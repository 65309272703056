<template>
    <div>
        <p class="vs-popup--subtitle">
            {{ $t("profile.explanation") }}
        </p>
        <div class="flex flez-wrap">
            <!-- CODI PER A PUJAR L'AVATAR D'USUARI -->
            <div class="w-1/2">
                <LabelForm>{{ $t("profile.label.avatar") }} (jpg)</LabelForm>   
                <ImageForm  
                id="profileImage"
                :validationWidthEqualsHeight="true"
                :validationMessage="validationAvatarMessage" 
                width="200" 
                height="200" 
                :src="avatar" 
                @updateImage="handlerUpdateAvatarImage"
                name="banner-image"/>             
            </div> 
            <div class="w-1/2">
                <div class="mb-2">
                    <vs-select 
                        :label="$t('profile.label.language')" 
                        v-model="language" 
                        class="w-full">
                        <vs-select-item v-for="lang in languages" :key="lang.id" :value="lang.acronym" :text="lang.name"/>
                    </vs-select>
                </div>
                <div class="mb-2">
                    <vs-select :label="$t('profile.label.timezone')" v-model="timezone" class="w-full">
                        <vs-select-item v-for="(item,index) in timezones" :key="index" :value="item.value" :text="item.text"  />
                    </vs-select>
                </div>
                <div class="mb-2">
                    <LabelForm>{{ $t("profile.label.password") }}</LabelForm> 
                    <vs-input  
                        type="password" 
                        class="w-full mr-2 mb-2"    
                        :danger="oldpassword_error"
                        :danger-text="$t('profile.label.passworderror')"
                        v-model="oldpassword" 
                    />
                </div>
                <div class="mb-2">
                    <LabelForm>{{ $t("profile.label.newpassword") }}</LabelForm> 
                    <vs-input 
                        type="password" 
                        class="w-full mr-2 mb-2" 
                        :danger="newpassword_error"
                        :danger-text="$t('profile.label.newpassworderror')"
                        v-model="newpassword" 
                        />
                </div>
                <div class="mb-2">
                    <LabelForm>{{ $t("profile.label.repeatpassword") }}</LabelForm> 
                    <vs-input 
                        type="password" 
                        class="w-full mr-2 mb-2" 
                        :danger="repeatednewpassword_error"
                        :danger-text="$t('profile.label.repeatpassworderror')"
                        v-model="repeatednewpassword" 
                    />
                </div>
                <div class="mt-16 text-right">
                    <vs-button type="filled" @click.prevent="saveProfile">
                        {{$t("profile.button.save")}}
                    </vs-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters,mapMutations } from 'vuex';
import AuthServices from '@/modules/Auth/Services/AuthServices.js'
import i18n from '@/i18n.js'
import timezones from '@/timezones.json'
import LabelForm from '@/modules/Shared/Components/form/LabelForm' 
import ImageForm from '@/modules/Shared/Components/form/ImageForm' 
import LanguageService from '@/modules/Shared/Services/LanguageService' 
export default {
    name:'profile-modal',
    components: {
        LabelForm,
        ImageForm,
    },
    data(){
        return {
        file:'',
        avatar:'',
        validationAvatarMessage:this.$t("profile.avatar.message"),
        language:'en',
        timezone:'',
        languages:[],
        timezones:[],
        oldpassword:'',
        newpassword:'',
        repeatednewpassword:'',
        oldpassword_error:false,
        newpassword_error:false,
        repeatednewpassword_error:false,
        }
    },
    methods:{
        ...mapMutations( ['UPDATE_USER_INFO','UPDATE_APPLICATION_LANGUAGE','UPDATE_USER_AVATAR']),
        handlerUpdateAvatarImage(file) {
            this.file = file;
        },
        async saveProfile() {
            this.oldpassword_error = 
            this.newpassword_error = 
            this.repeatednewpassword_error = false;
            
            if(this.oldpassword){
                if(this.newpassword && this.newpassword !==  this.repeatednewpassword){this.repeatednewpassword_error = true;}
            }
            
            if( !(this.oldpassword_error || this.newpassword_error || this.repeatednewpassword_error) ){
                const user_data = {language:this.language,timezone:this.timezone}
                const password_data = {newpassword:this.newpassword,oldpassword:this.oldpassword}
                await AuthServices.updateUserData(user_data);                
                if(this.oldpassword){
                    try{
                        await AuthServices.updateUserPasword(password_data);
                    }catch(error){
                        this.repeatednewpassword_error = true;
                    }
                } 

                // AIXÒ ÉS LA PART D'INTENTAR PUJAR L'AVATAR D'USUARI
                if(this.file){
                    let form = new FormData();
                    form.append('avatar',this.file)
                    await AuthServices.updateUserAvatar(form)
                }

                if( this.repeatednewpassword_error === false ){
                    //RESSETEGEM VALORS DELS CAMPS DE PASSWORD DEL FORMULARI
                    this.oldpassword =
                    this.newpassword =
                    this.repeatednewpassword ='';

                    this.UPDATE_USER_INFO({language:this.language,timezone:this.timezone})
                    this.UPDATE_APPLICATION_LANGUAGE(this.language);
                    this.UPDATE_USER_AVATAR(this.avatar);
                    i18n.locale = this.language
                    this.$emit('save')
                }
            }
        },
    },
    async beforeCreate(){
        this.timezones = Array.from(await timezones,x => {return {text:x, value:x}});            
    },
    async created(){
        this.languages = await LanguageService.getLanguages();
        this.language = this.AppActiveUser.language?this.AppActiveUser.language:this.languages[0].value
        this.timezone = this.AppActiveUser.timezone?this.AppActiveUser.timezone:this.timezones[0].value
        this.avatar = this.AppActiveUser.avatar['200x200']?this.AppActiveUser.avatar['200x200']:require('@/assets/images/profile/avatar_default.svg');   
    },
    computed: {
        ...mapGetters( ['AppActiveUser']),
    },

}
</script>

<style>
.vuesax-app-is-ltr  {
    font-weight: bold;
    margin-top: 2rem !important;
    font-size: 1rem;
    color: #242426;
}
</style>