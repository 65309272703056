import i18n from "@/i18n";


/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
export default [
  {
    url: "/",
    name: (() => i18n.t("dashboard.title"))(),
    slug: "home",
    icon: "HomeIcon"
  },
  {
    header: (() => i18n.t("support.title"))(),
    items: [
      {
        url: '/helpdesk',
        slug: "external",
        name: (() => i18n.t("helpdesk.title"))(),
        icon: "LifeBuoyIcon",
        target:"_blank"
      }
    ]
  }
]
