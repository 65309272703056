var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("img", {
    attrs: {
      src: require("@/assets/images/pages/logo.png"),
      width: "40px",
      heigth: "40px"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }