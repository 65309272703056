var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative" }, [
    _c(
      "div",
      { staticClass: "vx-navbar-wrapper", class: _vm.classObj },
      [
        _c(
          "vs-navbar",
          {
            staticClass: "vx-navbar navbar-custom navbar-skelton shadow-none",
            class: _vm.textColor,
            attrs: { color: _vm.navbarColorLocal }
          },
          [
            _c("feather-icon", {
              staticClass:
                "sm:inline-flex xl:hidden cursor-pointer p-2 text-black ",
              attrs: { icon: "MenuIcon" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.showSidebar.apply(null, arguments)
                }
              }
            }),
            _c("vs-spacer"),
            _c("profile-drop-down")
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }