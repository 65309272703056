var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "footer",
    { staticClass: "the-footer flex-wrap justify-between", class: _vm.classes },
    [
      _c("p", [
        _c("span", [_vm._v("COPYRIGHT ©")]),
        _c("span", [_vm._v(_vm._s(new Date().getFullYear()) + " ")]),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.spotlio.com",
              target: "_blank",
              rel: "nofollow"
            }
          },
          [_vm._v("Spotlio AG")]
        ),
        _c("span", { staticClass: "hidden sm:inline-block" }, [
          _vm._v(", All rights Reserved")
        ])
      ]),
      _c("a", { attrs: { href: "javascript:Cookiebot.show()" } }, [
        _vm._v("Cookie policy")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }