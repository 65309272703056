var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", { staticClass: "vs-popup--subtitle" }, [
      _vm._v(" " + _vm._s(_vm.$t("profile.explanation")) + " ")
    ]),
    _c("div", { staticClass: "flex flez-wrap" }, [
      _c(
        "div",
        { staticClass: "w-1/2" },
        [
          _c("LabelForm", [
            _vm._v(_vm._s(_vm.$t("profile.label.avatar")) + " (jpg)")
          ]),
          _c("ImageForm", {
            attrs: {
              id: "profileImage",
              validationWidthEqualsHeight: true,
              validationMessage: _vm.validationAvatarMessage,
              width: "200",
              height: "200",
              src: _vm.avatar,
              name: "banner-image"
            },
            on: { updateImage: _vm.handlerUpdateAvatarImage }
          })
        ],
        1
      ),
      _c("div", { staticClass: "w-1/2" }, [
        _c(
          "div",
          { staticClass: "mb-2" },
          [
            _c(
              "vs-select",
              {
                staticClass: "w-full",
                attrs: { label: _vm.$t("profile.label.language") },
                model: {
                  value: _vm.language,
                  callback: function($$v) {
                    _vm.language = $$v
                  },
                  expression: "language"
                }
              },
              _vm._l(_vm.languages, function(lang) {
                return _c("vs-select-item", {
                  key: lang.id,
                  attrs: { value: lang.acronym, text: lang.name }
                })
              }),
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mb-2" },
          [
            _c(
              "vs-select",
              {
                staticClass: "w-full",
                attrs: { label: _vm.$t("profile.label.timezone") },
                model: {
                  value: _vm.timezone,
                  callback: function($$v) {
                    _vm.timezone = $$v
                  },
                  expression: "timezone"
                }
              },
              _vm._l(_vm.timezones, function(item, index) {
                return _c("vs-select-item", {
                  key: index,
                  attrs: { value: item.value, text: item.text }
                })
              }),
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mb-2" },
          [
            _c("LabelForm", [_vm._v(_vm._s(_vm.$t("profile.label.password")))]),
            _c("vs-input", {
              staticClass: "w-full mr-2 mb-2",
              attrs: {
                type: "password",
                danger: _vm.oldpassword_error,
                "danger-text": _vm.$t("profile.label.passworderror")
              },
              model: {
                value: _vm.oldpassword,
                callback: function($$v) {
                  _vm.oldpassword = $$v
                },
                expression: "oldpassword"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mb-2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("profile.label.newpassword")))
            ]),
            _c("vs-input", {
              staticClass: "w-full mr-2 mb-2",
              attrs: {
                type: "password",
                danger: _vm.newpassword_error,
                "danger-text": _vm.$t("profile.label.newpassworderror")
              },
              model: {
                value: _vm.newpassword,
                callback: function($$v) {
                  _vm.newpassword = $$v
                },
                expression: "newpassword"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mb-2" },
          [
            _c("LabelForm", [
              _vm._v(_vm._s(_vm.$t("profile.label.repeatpassword")))
            ]),
            _c("vs-input", {
              staticClass: "w-full mr-2 mb-2",
              attrs: {
                type: "password",
                danger: _vm.repeatednewpassword_error,
                "danger-text": _vm.$t("profile.label.repeatpassworderror")
              },
              model: {
                value: _vm.repeatednewpassword,
                callback: function($$v) {
                  _vm.repeatednewpassword = $$v
                },
                expression: "repeatednewpassword"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-16 text-right" },
          [
            _c(
              "vs-button",
              {
                attrs: { type: "filled" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.saveProfile.apply(null, arguments)
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("profile.button.save")) + " ")]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }