var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-dropdown",
    {
      staticClass: "cursor-pointer",
      attrs: { "vs-custom-content": "", "vs-trigger-click": "" }
    },
    [
      _vm.AppActiveUser.username
        ? _c(
            "div",
            { staticClass: "the-navbar__user-meta flex items-center" },
            [
              _c(
                "div",
                { staticClass: "text-right leading-tight hidden sm:block" },
                [
                  _c("p", { staticClass: "font-semibold active-user m-0" }, [
                    _vm._v(_vm._s(_vm.AppActiveUser.username))
                  ])
                ]
              ),
              _c("div", { staticClass: "con-img ml-3" }, [
                _vm.AppActiveUser.avatar["50x50"]
                  ? _c("img", {
                      key: "onlineImg",
                      staticClass: "rounded-full cursor-pointer block",
                      attrs: {
                        src: _vm.AppActiveUser.avatar["50x50"],
                        alt: "user-img",
                        width: "40",
                        height: "40"
                      }
                    })
                  : _c("img", {
                      key: "onlineImg",
                      staticClass: "rounded-full cursor-pointer block",
                      attrs: {
                        src: require("@/assets/images/profile/avatar_default.svg"),
                        alt: "user-img",
                        width: "40",
                        height: "40"
                      }
                    })
              ]),
              _c(
                "vs-dropdown-menu",
                {
                  staticClass: "vx-navbar-dropdown",
                  attrs: { active: _vm.popupActive },
                  on: {
                    "update:active": function($event) {
                      _vm.popupActive = $event
                    }
                  }
                },
                [
                  _c("ul", { staticStyle: { "min-width": "9rem" } }, [
                    _c(
                      "li",
                      {
                        staticClass:
                          "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                        attrs: { disabled: "" },
                        on: {
                          click: function($event) {
                            _vm.modalIsOpened = true
                          }
                        }
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "UserIcon", svgClasses: "w-4 h-4" }
                        }),
                        _c("span", { staticClass: "ml-2" }, [_vm._v("Profile")])
                      ],
                      1
                    ),
                    _c(
                      "li",
                      {
                        staticClass:
                          "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                        on: { click: _vm.logout }
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "LogOutIcon", svgClasses: "w-4 h-4" }
                        }),
                        _c("span", { staticClass: "ml-2" }, [_vm._v("Logout")])
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "vs-popup",
                {
                  staticClass: "profile-modal--wrapper",
                  attrs: {
                    classContent: "profile-modal--content",
                    title: _vm.$t("Profile"),
                    active: _vm.modalIsOpened
                  },
                  on: {
                    "update:active": function($event) {
                      _vm.modalIsOpened = $event
                    }
                  }
                },
                [
                  _c("profile-modal", {
                    on: {
                      save: function($event) {
                        _vm.modalIsOpened = false
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }