<template>
<vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
  <div class="the-navbar__user-meta flex items-center" v-if="AppActiveUser.username">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold active-user m-0">{{ AppActiveUser.username }}</p>
      <!-- <small>{{ AppActiveUser.userRole }}</small> -->
    </div>

      <div class="con-img ml-3">
        <img v-if="AppActiveUser.avatar['50x50']" key="onlineImg" :src="AppActiveUser.avatar['50x50']" alt="user-img" width="40" height="40" class="rounded-full cursor-pointer block" />
        <img v-else key="onlineImg" src="@/assets/images/profile/avatar_default.svg" alt="user-img" width="40" height="40" class="rounded-full cursor-pointer block" />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown" :active.sync="popupActive">
        <ul style="min-width: 9rem">

          <li disabled
          class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
          @click="modalIsOpened=true"
          >
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li>

          <!-- <vs-divider class="m-1" /> -->

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
      <vs-popup class="profile-modal--wrapper" classContent="profile-modal--content"  :title="$t('Profile')" :active.sync="modalIsOpened">
        <profile-modal @save="modalIsOpened=false"></profile-modal>
    </vs-popup>
      
  </div>
</vs-dropdown>
</template>

<script>
import ProfileModal from './ProfileModal'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      popupActive:false,
      modalIsOpened:false,
    }
  },
  components:{
    ProfileModal
  },
  computed: {
    ...mapGetters( ['AppActiveUser']),
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
    },
  }
}
</script>
<style scoped>
  .active-user{
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
  }
</style>
