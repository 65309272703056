import { render, staticRenderFns } from "./VxAutoSuggest.vue?vue&type=template&id=a0acb480&"
import script from "./VxAutoSuggest.vue?vue&type=script&lang=js&"
export * from "./VxAutoSuggest.vue?vue&type=script&lang=js&"
import style0 from "./VxAutoSuggest.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a0acb480')) {
      api.createRecord('a0acb480', component.options)
    } else {
      api.reload('a0acb480', component.options)
    }
    module.hot.accept("./VxAutoSuggest.vue?vue&type=template&id=a0acb480&", function () {
      api.rerender('a0acb480', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/Template/Components/vx-auto-suggest/VxAutoSuggest.vue"
export default component.exports