var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showFullSearch,
              expression: "showFullSearch"
            }
          ],
          staticClass:
            "search-full-container w-full h-full absolute left-0 top-0",
          class: { flex: _vm.showFullSearch }
        },
        [
          _c("vx-auto-suggest", {
            ref: "navbarSearch",
            staticClass: "w-full",
            attrs: {
              autoFocus: _vm.showFullSearch,
              data: _vm.navbarSearchAndPinList,
              search_key: "title",
              "background-overlay": "",
              inputClassses:
                "w-full vs-input-no-border vs-input-no-shdow-focus",
              icon: "SearchIcon",
              placeholder: "Explore Vuexy..."
            },
            on: {
              input: _vm.hnd_search_query_update,
              selected: _vm.selected,
              closeSearchbar: function($event) {
                _vm.showFullSearch = false
              }
            },
            scopedSlots: _vm._u([
              {
                key: "group",
                fn: function(ref) {
                  var group_name = ref.group_name
                  return [
                    _c("p", { staticClass: "font-semibold text-primary" }, [
                      _vm._v(_vm._s(_vm._f("title")(group_name)))
                    ])
                  ]
                }
              },
              {
                key: "pages",
                fn: function(ref) {
                  var suggestion = ref.suggestion
                  return [
                    _c(
                      "div",
                      { staticClass: "flex items-end leading-none py-1" },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-4",
                          attrs: {
                            icon: suggestion.icon,
                            svgClasses: "h-5 w-5"
                          }
                        }),
                        _c("span", { staticClass: "mt-1" }, [
                          _vm._v(_vm._s(suggestion.title))
                        ])
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "noResult",
                fn: function(ref) {
                  var group_name = ref.group_name
                  return [
                    _c(
                      "div",
                      { staticClass: "flex items-center" },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-4",
                          attrs: { icon: "InfoIcon", svgClasses: "h-5 w-5" }
                        }),
                        _c("span", [_vm._v("No results found.")])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c(
            "div",
            { staticClass: "absolute right-0 h-full z-50" },
            [
              _c("feather-icon", {
                staticClass: "px-4 cursor-pointer h-full close-search-icon",
                attrs: { icon: "XIcon" },
                on: {
                  click: function($event) {
                    _vm.showFullSearch = false
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("feather-icon", {
        staticClass: "cursor-pointer navbar-fuzzy-search mr-4",
        attrs: { icon: "SearchIcon" },
        on: {
          click: function($event) {
            _vm.showFullSearch = true
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }