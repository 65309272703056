var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "h-nav-group relative",
      class: [
        { "h-nav-group-open": _vm.openItems },
        { "h-nav-group-active": _vm.open },
        { "disabled-item pointer-events-none": _vm.group.isDisabled }
      ],
      on: { mouseover: _vm.mouseover, mouseleave: _vm.mouseout }
    },
    [
      _c(
        "div",
        { staticClass: "group-header w-full flex items-center" },
        [
          _c(
            "span",
            { staticClass: "flex items-center w-full" },
            [
              _vm.group.icon || this.groupIndex > Math.floor(this.groupIndex)
                ? _c("feather-icon", {
                    attrs: {
                      icon: _vm.group.icon || "CircleIcon",
                      svgClasses: _vm.iconClasses
                    }
                  })
                : _vm._e(),
              _c("span", { staticClass: "truncate mr-3 select-none" }, [
                _vm._v(_vm._s(_vm.group.name))
              ])
            ],
            1
          ),
          _c("feather-icon", {
            class: [{ rotate90: _vm.openItems }, "feather-grp-header-arrow"],
            attrs: {
              icon: _vm.bottom
                ? "ChevronDownIcon"
                : _vm.$vs.rtl
                ? "ChevronLeftIcon"
                : "ChevronRightIcon",
              "svg-classes": "w-4 h-4"
            }
          })
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade-bottom-2x" } }, [
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.openItems,
                expression: "openItems"
              }
            ],
            ref: "childDropdown",
            staticClass:
              "h-nav-group-items h-nav-menu-dd absolute shadow-drop py-2",
            style: _vm.styleItems
          },
          _vm._l(_vm.group.submenu, function(groupItem, index) {
            return _c(
              "li",
              { key: index },
              [
                groupItem.submenu
                  ? _c("h-nav-menu-group", {
                      attrs: {
                        group: groupItem,
                        groupIndex: Number(_vm.groupIndex + "." + (index + 1)),
                        open: _vm.isGroupActive(groupItem),
                        openHover: _vm.openHover
                      }
                    })
                  : _c(
                      "h-nav-menu-item",
                      {
                        attrs: {
                          "icon-small": "",
                          index: _vm.groupIndex + "." + index,
                          to:
                            groupItem.slug !== "external"
                              ? groupItem.url
                              : null,
                          href:
                            groupItem.slug === "external"
                              ? groupItem.url
                              : null,
                          icon: _vm.itemIcon,
                          slug: groupItem.slug,
                          target: groupItem.target
                        }
                      },
                      [
                        _c("span", { staticClass: "truncate" }, [
                          _vm._v(_vm._s(groupItem.name))
                        ]),
                        groupItem.tag
                          ? _c(
                              "vs-chip",
                              {
                                staticClass: "ml-auto",
                                attrs: { color: groupItem.tagColor }
                              },
                              [_vm._v(_vm._s(groupItem.tag))]
                            )
                          : _vm._e()
                      ],
                      1
                    )
              ],
              1
            )
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }