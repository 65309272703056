import store from "../Store/store";
import navMenuItems from "@/layouts/components/vertical-nav-menu/navMenuItems.js"

const navigationSections = {
  apps: {
    name: 'Apps',
    icon: "SmartphoneIcon",
    id: "apps",
  },
  resorts: {
    name: 'Resorts',
    icon: "BoxIcon",
    id: 'resorts',
  },
  shops: {
    name: "Shops",
    icon: "ShoppingBagIcon",
    id: 'shops',
  }
};

const generateNavigation = () => {
  const drm = store.state.auth.drm

  const navigation = [...navMenuItems]

  const sections = Object.keys(navigationSections)
    .filter(section => drm[section].length > 0)
    .map(section => createSection(drm[section], section))

  navigation.splice(1, 0, ...sections)

  if (drm.admin.length > 1 || (drm.admin.length === 1 && !drm.admin[0].drm.includes('ria'))) {
    navigation.splice(0, 1, {
        url: "/admin",
        name: "Admin",
        slug: "admin",
        icon: "HomeIcon"
    })
  }

  return navigation
}

const createNavMenuItemsFromArray = (itemsArray, baseURL, slug) => {
  return itemsArray.map(item => {
    return {
      url: `${baseURL}/${item.uuid}`,
      name: item.name,
      slug: slug,
    };
  })
}

const createSection = (items, section) => {
    return {
      ...navigationSections[section],
      submenu: createNavMenuItemsFromArray(items, '/' + section, section)
  }
}

export default generateNavigation
